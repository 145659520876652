import "./src/styles/global.css";

//On every page
export const onRouteUpdate = () => {
  var menuButton = document.getElementById("menu");
  var nav = document.getElementById("nav");

  if (menuButton && nav) {
    if (nav.classList.contains("hidden")) {
      menuButton.addEventListener("click", function (event) {
        nav.classList.remove("hidden");
        nav.addEventListener("click", function (event) {
          nav.classList.add("hidden");
        });
      });
    }

    if (!nav.classList.contains("hidden")) {
      menuButton.addEventListener("click", function (event) {
        nav.classList.add("hidden");
      });
    }
  }
};

/*On first load (persistent)
const addScript = (url) => {
  const script = document.createElement("script");
  script.src = url;
  script.async = true;
  document.body.appendChild(script);
};

export const onClientEntry = () => {
  window.onload = () => {
    addScript(
      "https://cdn-cookieyes.com/client_data/1f8d96040b97940efbb5b141/script.js" // Moved to GTM
    );
  };
};
*/
